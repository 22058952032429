import { EventHandler } from "./EventHandler";
import { getCookie } from "./utils";

const PROD_BACKEND = "https://api.provizxr.com";
const DEV_BACKEND = "https://devapi.provizxr.com";
const LOCAL_BACKEND = "https://localhost:5001";

const PROD_FRONTEND = "https://studio.provizxr.com";
const DEV_FRONTEND = "http://dev.studio.provizxr.com";
const QA_FRONTEND = "http://qa.studio.provizxr.com";
const LOCAL_FRONTEND = "http://localhost:3000";

const ImpersonateCookieName = "impersonateId";

/** Get full url from filename. */
export function getMediaFilePath(fileName: string) {
  return ProVizConfig.selfContained
    ? `${ProVizConfig.selfContainedRoot}v1/file/${fileName}`
    : `https://proviz.blob.core.windows.net/files/${fileName}`;
}

export class ProVizConfig extends EventHandler {
  public static token?: string;
  public static userKey?: string;
  public static machineKey?: string;
  public static impersonateCompanyId: string | undefined;
  public static env: string = "development";
  public static selfContained: boolean = false;
  public static selfContainedRoot: string = "";
  public static frontend: string = DEV_FRONTEND;
  public static backend: string = DEV_BACKEND;
  public static callbackUri: string = `${ProVizConfig.frontend}/callback`;
  public static events: EventHandler = new EventHandler();

  public static impersonateCompany(id: string) {
    this.impersonateCompanyId = id;
    this.setImpersonateCookie(id);
    this.events.dispatch("impersonate-change", null);
  }

  private static setImpersonateCookie(id: string) {
    const d = new Date();
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = `${ImpersonateCookieName}=${id};${expires} ";path=/`;
  }

  private static getImpersonateCookie() {
    return getCookie(ImpersonateCookieName);
  }

  public static setEnv(env: string, accessToken: string) {
    ProVizConfig.token = accessToken;
    ProVizConfig.env = env;
    switch (env) {
      case "package":
        ProVizConfig.frontend = LOCAL_FRONTEND;
        ProVizConfig.backend = LOCAL_BACKEND;
        break;
      case "local":
        ProVizConfig.frontend = LOCAL_FRONTEND;
        ProVizConfig.backend = LOCAL_BACKEND;
        break;
      case "development":
        ProVizConfig.frontend = DEV_FRONTEND;
        ProVizConfig.backend = DEV_BACKEND;
        break;
      case "qa":
        ProVizConfig.frontend = QA_FRONTEND;
        ProVizConfig.backend = PROD_BACKEND;
        break;
      default:
        ProVizConfig.frontend = PROD_FRONTEND;
        ProVizConfig.backend = PROD_BACKEND;
        break;
    }
    ProVizConfig.callbackUri = `${ProVizConfig.frontend}/callback`;
    const id = this.getImpersonateCookie();
    if (id) {
      this.impersonateCompany(id);
    }
  }
}
