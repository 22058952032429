import { Link, useLocation } from 'react-router-dom'
import React, { ReactElement } from 'react'

interface Props {
    onclick?: Function;
    to?: string;
    render: (isActive: boolean) => ReactElement;
    children?: any;
}

export default function NavButton(props: Props): ReactElement {
    const location = useLocation();

    if (props.to) {
        return <div className={`flex-1 center nav-button ${location.pathname === props.to ? 'active-nav' : 'inactive-nav'}`} onClick={() => props.onclick && props.onclick()}>
            <Link to={props.to}>
                {props.render(location.pathname === props.to)}
            </Link>
        </div>
    }
    return <div className="flex-1 center nav-button" onClick={() => props.onclick && props.onclick()}>
        {props.children}
    </div>
}
