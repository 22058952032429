import React, { ReactElement } from 'react'
import Vuplex from '../utils/vuplex'
import Nav from './Nav'
import NavButton from './NavButton'

export default function SiteNav(): ReactElement {
    return <Nav>
        <NavButton to="/"
          render={(isActive) => (
            <>
              <img className="full-height" src={isActive ? 'Icons/Home Icon (purple).png' : 'Icons/Home Icon (grey).png'} alt="home icon" />
              <div className="nav-text" style={{paddingTop: '7px'}}>Home</div>
            </>
          )}
        />
        <NavButton to="/dashboard"
          render={(isActive) => (
            <>
              <img className="full-height" src={isActive ? 'Icons/Studio Icon (purple).png' : 'Icons/Studio Icon (grey).png'} alt="contact icon" />
              <div className="nav-text" style={{paddingTop: '7px'}}>The Studio</div>
            </>
          )}
        />
        <NavButton to="/#" onclick={() => { Vuplex('qr-scan') }}
          render={(isActive) => (
            <>
              <img className="full-height" src={isActive ? "Icons/Scanner Icon (purple).png" : "Icons/Scanner Icon (grey).png"} alt="qr icon" />
              <div className="nav-text" style={{paddingTop: '7px'}}>Scanner</div>
            </>
          )}
        />
    </Nav>
}
