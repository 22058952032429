export default function Vuplex(type: string, data?: any) {
    const w: any = global.window;
    const vuplex: any = w.vuplex;

    if (vuplex) {
        vuplex.postMessage({
            type: type,
            message: data
        });
    } else {
        console.log('Vuplex not found:', type, data);
    }
}

export const VuplexHandler: { [messageType: string]: (message: any) => void } = {};

function GlobalVuplexHandler() {
    const w: any = global.window;
    if (w) {
        const vuplex: any = w.vuplex;
        if (vuplex) {
            vuplex.addEventListener('message', function (event: any) {
                let json = event.data;
                let messageType = json.type;
                let message = json.message;

                console.log('JSON received: ' + json);

                if (VuplexHandler[messageType]) {
                    VuplexHandler[messageType](message);
                }
            });
        } else {
            console.log('Vuplex not found');
        }
    } else {
        console.error('Window not defined');
    }
}
GlobalVuplexHandler();
