import React, { ReactElement, useEffect, useState } from 'react'
import { APIScene, initAbortController, isAbortError, Paged, ProVizConfig, SceneService } from '@proviz/api-services';
import Card from '../components/Card';
import Vuplex from '../utils/vuplex';
import { CompanySelector } from './CompanySelector';
import PagingControl from '../components/PagingControl';

export default function Scenes(): ReactElement {
    const [scenes, setScenes] = useState<APIScene[]>([]);
    const [page, setPage] = useState<number>(0);
    const [pagesTotal, setPagesTotal] = useState<number>(0);
    const [failed, setFailed] = useState<boolean>(false);

    useEffect(() => {
        const abortController = initAbortController();
        const getUpdatedScenes = () => {
            SceneService.paged(page, 10, undefined, { abortController })
                .then((pages: Paged<APIScene>) => {
                    setScenes(pages.data);
                    setPagesTotal(pages.pagesTotal);
                }).catch((e) => {
                    if (isAbortError(e)) return; // if the query was aborted, do nothing
                    console.error(e);
                    setFailed(true);
                });
        }
        getUpdatedScenes();
        ProVizConfig.events.addListener('impersonate-change', getUpdatedScenes);
        return () => {
            ProVizConfig.events.removeListener('impersonate-change', getUpdatedScenes);
            abortController.abort();
        }
    }, [setScenes, page]);

    return <div>
        <CompanySelector />
        <div className='scenes'>
            {failed
                ? 'We had trouble getting scenes.'
                : scenes.map((scene) =>
                    <Card 
                        small={true} 
                        key={scene.id} 
                        title={scene.name} 
                        cardType={scene.sceneType} 
                        thumbnail={scene.thumbnailFile?.location || 'https://studio.provizxr.com/images/thumbnail.svg'} 
                        action='Launch Experience' 
                        onClickEdit={() => {
                            Vuplex('load-builder', {
                                id: scene.id
                            })
                        }}
                        onClick={() => {
                            Vuplex('load-scene', {
                                id: scene.id
                            })
                        }} />)}
        </div>
        <div className='searchBar'>
            <PagingControl page={page} totalPages={pagesTotal} pageSet={(p: number) => setPage(p)} />
        </div>
    </div>;
}