import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './app.scss'
import SiteNav from './components/SiteNav'
import Dashboard from './containers/Dashboard'
import Callback from './auth/Callback'
import Home from './pages'

// Any routes that start with 'dynamic' will be treated as non-static routes

function App() {
  return (
    <>
      <div className="content">
        <React.Suspense fallback={<em>Loading...</em>}>
          <Router>
            <Switch>
              <Dashboard path="/dashboard" />
              <Callback path="/callback" />
              <Route path="/" component={Home} />

              <Route path="*" />
            </Switch>
            <SiteNav />
          </Router>
        </React.Suspense>
      </div>
    </>
  )
}

export default App;
