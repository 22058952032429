import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Vuplex from "../utils/vuplex";

const LogoutLink = () => {
  const { logout } = useAuth0();

  return (
    <button className="welcome-logout logout-button" onClick={() => {
      Vuplex('logout');
      logout({returnTo: `${window.location.origin}/`});
    }}>
      Logout
    </button>
  );
};

export default LogoutLink;