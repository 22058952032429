import ContentContainer from '../components/ContentContainer';
import React, { ReactElement } from 'react'
import AuthButton from '../auth/AuthButton';
import withToken from '../auth/WithToken'
import DashboardContent from '../components/DashboardContent';

interface Props {
    children?: any;
    path: string;
}

export default function Dashboard(props: Props): ReactElement {

    const result = withToken(DashboardContent)({});

    return <ContentContainer>
        {result}
        <AuthButton />
        {props.children}
    </ContentContainer>;
}
