import React, { ReactElement } from 'react'

interface Props {
    thumbnail: string;
    title: string;
    cardType: string;
    desc?: string;
    action: string;
    onClick: Function;
    onClickEdit?: Function;
    small?: boolean;
}

export default function Card(props: Props): ReactElement {
    const { title, thumbnail, cardType, desc, action } = props;

  return <div className={`card large-text upper color-white bg-white height-auto pad-15 ${props.small ? 'small' : ''}`}>
        <div className='thumbnail-container'>
            <div className='thumbnail full-width' style={{'backgroundImage': `url(${thumbnail})`}}></div>
        </div>
        <div className="content">
            <h4 className="medium-text"><b>{title}</b> <span>|</span> {cardType}</h4>
            {desc && <div>
                {desc}
            </div>}
            { cardType === 'Experience' && <button className='experience-button' onClick={() => props.onClick()}>{action}</button>}
            { cardType !== 'Experience' && <div className='card-actions'>
                <div className='edit'>
                    <button className='experience-button' onClick={() => props.onClickEdit()}>Edit</button>
                </div>
                <div className='launch'>
                    <button className='experience-button' onClick={() => props.onClick()}>{action}</button>
                </div>
            </div>}
        </div>
    </div>;
}