import { BaseDataService, IServiceOptions } from "../BaseDataService";
import { APIScene, Paged, SceneType } from "../models";
import { ResourceLink } from "../types/resourceLink";

class APISceneService extends BaseDataService<any> {
  constructor() {
    super("scene");
  }

  public async getData(id: string, options?: IServiceOptions): Promise<string> {
    return await this.request(`${id}/data`, options);
  }

  public async pagedByFolder(
    folderId: string,
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIScene>> {
    return await this.request(
      `paged/${folderId}?page=${page}&size=${size}${filter ? `&filter=${filter}` : ""}`,
      options
    );
  }

  public async getZippedData(
    id: string,
    options?: IServiceOptions
  ): Promise<string> {
    return await this.request(`${id}/gzip`, options);
  }

  public async create(
    data: { name: string; sceneType: SceneType, folderId: string | null },
    options?: IServiceOptions
  ): Promise<APIScene> {
    return await this.post("", data, options);
  }

  public async update(
    scene: APIScene,
    options?: IServiceOptions
  ): Promise<APIScene> {
    return await this.request(`${scene.id}`, {
      method: "PUT",
      body: scene,
      ...options,
    });
  }

  public async publish(id: string, state: boolean, options?: IServiceOptions) {
    return await this.post(`${id}/publish/${state}`);
  }

  public async package(id: string, options?: IServiceOptions) {
    await this.download(`${id}.zip`, { path: `${id}/package`, ...options });
  }

  public async createPackage(resourceLinks: ResourceLink[], options?: IServiceOptions) {
    await this.download(`scene.zip`, { path: `package`, ...options, method: 'POST', body: {resourceLinks}, forceJSONContent: true });
  }

  public async setPackage(id: string, resourceLinks: ResourceLink[], options?: IServiceOptions) {
    await this.download(`scene.zip`, { path: `${id}/package`, ...options, method: 'POST', body: {resourceLinks}, forceJSONContent: true });
  }

  public async SetThumbnail(
    sceneId: string,
    file: File,
    options?: IServiceOptions
  ) {
    const body = new FormData();
    body.append("thumbnail", file);
    return await this.submit(`${sceneId}/thumbnail`, "POST", body, options);
  }

  public async getVersionHistory(sceneId: string, options?: IServiceOptions) {
    return await this.request(`${sceneId}/versionHistory`, options);
  }

  public async getDraft(
    sceneId: string,
    options?: IServiceOptions
  ): Promise<APIScene> {
    return await this.request(`${sceneId}/draft`, options);
  }

  public async getVersion(
    sceneId: string,
    version: number,
    options?: IServiceOptions
  ): Promise<APIScene> {
    return await this.request(`${sceneId}/version/${version}`, options);
  }

  public async setFolder(
    sceneId: string,
    folderId: string | null
  ): Promise<APIScene> {
    if (folderId === null)
      return await this.request(`${sceneId}/folder`, { method: 'POST' });
    return await this.request(`${sceneId}/folder/${folderId}`, { method: 'POST' });
  }
}

const SceneService = new APISceneService();

export default SceneService;
