import React, { useEffect, useState } from 'react';
import { CompanyService, getDefaultCompanyId, ProVizConfig } from "@proviz/api-services";
import { useUserContext } from '../auth/UserContext';
import Vuplex from '../utils/vuplex';

interface KeyValuePair {
    key: string;
    value: string;
}

export const CompanySelector = () => {
    const user = useUserContext();
    const [companies, setCompanies] = useState<KeyValuePair[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (ProVizConfig.impersonateCompanyId) {
            setSelectedCompanyId(ProVizConfig.impersonateCompanyId);
        } else if (user) {
            setSelectedCompanyId(getDefaultCompanyId(user));
        }
    }, [user]);


    useEffect(() => {
        CompanyService.getAllKeyValue().then((x => {
            setCompanies(x.sort((a, b) => {
                if (a.value.toLowerCase() > b.value.toLowerCase()) {
                    return 1;
                }
                if (a.value.toLowerCase() < b.value.toLowerCase()) {
                    return -1;
                }
                return 0;
            }));
        }));
    }, [user]);

    const impersonate = async (id: string) => {
        ProVizConfig.impersonateCompany(id);
        Vuplex('company-id', { companyId: id });
        setSelectedCompanyId(id);
    }

    if (user && companies.length > 1) {
        return <div className="company-selector">
            <label htmlFor="company-selector"><div className="active-org">Active Organization</div></label>
            <select name="company-selector" id="company-selector" style={{ width: '100%' }} className="selector" value={selectedCompanyId} onChange={(e) => impersonate(e.target.value)}>
                {companies.map((x: KeyValuePair) =>
                    <option value={x.key} key={x.key}>
                        {x.value}
                    </option>
                )}
            </select>
        </div>;
    }

    return <></>
}
