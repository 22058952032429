import React, { ReactElement, useEffect, useState } from 'react'
import Vuplex, { VuplexHandler } from '../utils/vuplex';

export default function VuforiaAreaTargetCreator(): ReactElement {
    const [areaTargetSupported, setAreaTargetSupported] = useState<Boolean>(true);

    useEffect(() => {
        Vuplex('feature-check');
        VuplexHandler['lidar'] = (message: any) => {
            if (message === true) {
                setAreaTargetSupported(true);
            } else {
                console.log('LiDAR is not supported on this device');
            }
        }
        return () => {
            delete VuplexHandler['lidar'];
        }
    }, []);

    if (!areaTargetSupported) {
        return <></>;
    }

    return <div>
        <button onClick={() => {
            Vuplex('area-target-creator');
        }}>Create Area Target</button>
    </div>;
}