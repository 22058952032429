import Card from '../components/Card';
import React from 'react'
import Vuplex from '../utils/vuplex';
import Banner from '../components/Banner';

const bgImage = "Images/ProVizXR-Branded-image-37.jpg";

export const Home = () => (
  <div style={{ textAlign: 'center' }}>
    <div className="site-content">
        <Banner />
        <div className="padded-50 large-text bg flex color-white" style={{ 'backgroundImage': `url("${bgImage}")`}}>
            <div className="padded-content">
                &nbsp;
            </div>
            <div className="flex-1 margin-left-25 text-left">
                Building<br />Transformative<br />Digital Solutions<br />that Drive<br />Your Future.
            </div>
        </div>
        <div className="padded-50">
            <h1 className="color-dark-purple bold">Experience ProVizXR</h1>
            <div>
                View our Case Studies or launch our immersive XR experiences right from your device!
            </div>
            <div className="selections">
                <Card thumbnail='Images/Nutrilite_cropped.jpg' title='Nutrilite' cardType='Experience' desc='Check out the Nutrilite AR Experience and explore the guided tour of this facility using ProVizXR Tours.' action='Launch Experience' onClick={() => { Vuplex('external', 'http://experiencenutrilitevirtualtour.com/') }} />
                <Card thumbnail='Images/Spire_cropped.png' title='Spire Energy' cardType='Experience' desc='Try the in app AR experience and explore some of the powerful capabilities that the ProVizXR platform can provide.' action='Launch Experience' onClick={() => { Vuplex('load-scene', { id: 'c951c2b1-41cd-4cfa-4264-08d97e1b728b' })}} />
            </div>
        </div>
        <div className="padded-50 dark-purple color-white">
            <div className="center">
                <img src="Icons/ProVizXR Symbol.png" className="full-width" style={{maxWidth: '300px'}} />
            </div>
            <h3 className="center large-text">Why ProVizXR?</h3>
            <div className="medium-text center">
                ProVizXR is the first "All-Realities" platform that follows a "once-in-many-out" philosophy. Why does this matter? Currently, VR/AR/MR exist in silos and the development of reality has its own set of development demands. We believe that in the near future, all realities will merge and the core aspects of each experience will transcend across realities.
            </div>
        </div>
        <div className="padded-50">
            <div className="center">
                <img src="Logos/Th3rdEye Logo.png" className="full-width" style={{maxWidth: '400px'}} />
            </div>
            <br />
            <div className="center medium-text">
                Discover the newest advances in XR technology.
            </div>
            <br />
            <div className="selections">
                <a href="#" onClick={() => Vuplex('external', 'https://th3rdeyexr.com/category/industry-insights/training/')} className="card large-text upper color-white padded-25" style={{backgroundImage: "url('Images/Training\ 2.jpg')"}}>
                    XR Training
                </a>
                <a href="#" onClick={() => Vuplex('external', 'https://th3rdeyexr.com/category/industry-insights/marketing/')} className="card large-text upper color-white padded-25" style={{backgroundImage: "url('Images/Marketing\ 2.jpg')"}}>
                    XR Marketing
                </a>
                <a href="#" onClick={() => Vuplex('external', 'https://th3rdeyexr.com/category/interviews/')} className="card large-text upper color-white padded-25" style={{backgroundImage: "url('Images/Interview\ 2.jpg')"}}>
                    XR Interviews
                </a>
            </div>
        </div>
    </div>
  </div>
)
export default Home