import { IHasId } from "./interfaces/IHasId";

export type PermissionEntity =
  | "Model"
  | "Texture"
  | "Image"
  | "Sprite"
  | "Scene"
  | "User"
  | "Company"
  | "File"
  | "Target"
  | "MachineKey";

export type PermissionType = "View" | "Edit" | "Delete";

export function hasAccess(
  permissions: APIPermission[],
  entity: PermissionEntity,
  accessLevel: PermissionType
) {
  const el = permissions.find(
    (p) =>
      p.permissionEntity === entity &&
      (p.permissionTypes ?? []).includes(accessLevel)
  );
  return el;
}

export interface APIPermission extends IHasId {
  id: string;
  name: string;
  permissionTypes: PermissionType[];
  permissionEntity: PermissionEntity;
  created: string;
  updated: string;
}
