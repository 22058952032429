import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react'
import { createRoot } from 'react-dom/client';

// Your top level component
import App from './App'

const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'th3rdcoast.auth0.com';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || 'w4t3JyOV7h2efRyHWZccf7D6xqQmfdFU';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.provizxr.com';

// Export your top level component as JSX (for static rendering)
export default App

// Render your app
if (typeof document !== 'undefined') {
  const target = document.getElementById('root')

  const root = createRoot(target!); 

  root.render( 
          <Auth0Provider
          domain={domain}
          clientId={clientId}
          redirectUri={`${window.location.origin}/dashboard`}
          audience={audience}
          scope="openid email profile"
        >
          <App />
        </Auth0Provider>)
}
