import React, {
    ComponentType, FunctionComponent, useEffect,
    useState
} from "react";
import { useAuth0, WithAuthenticationRequiredOptions, } from "@auth0/auth0-react";
import { AccessToken } from "./AccessToken";
import { AccessKeyService, ProVizConfig, UserKeyService, UserService } from "@proviz/api-services";
import { useHistory } from 'react-router-dom';
import { UserContext } from "./UserContext";
import Vuplex from "../utils/vuplex";

const initialSearchURL = global.window?.location.search;

const defaultReturnTo = (): string =>
    `${global.window?.location.pathname}${global.window?.location.search}`;
const defaultOnRedirecting = (): JSX.Element => <></>;

export interface TokenProps { token: any }

/**
* This HOC copies most of the logic from the Auth0 WithAuthenticationRequired
* with authentication required HOC. With the addition of logic that waits
* to render the child component until a JWT has been pulled down. 
*/
const withToken = <P extends object>(
    Component: ComponentType<P>,
    options: WithAuthenticationRequiredOptions = {}
): FunctionComponent<P> => {
    return function WithToken(props: P): JSX.Element {
        const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
        const [token, setToken] = useState<string | undefined>(undefined);
        const [user, setUser] = useState<any>(AccessToken.user);
        const history = useHistory();

        const {
            returnTo = defaultReturnTo,
            onRedirecting = defaultOnRedirecting,
            loginOptions = {},
        } = options;
        const { getAccessTokenSilently } = useAuth0();

        useEffect(() => {
            if (!isAuthenticated || isLoading) {
                return;
            }

            let mounted = true;

            // Check server if there is an entry for this user in db
            // Create user if there is not
            const getUser = async () => {
                try {
                    console.log("get user", ProVizConfig.backend, ProVizConfig.env)
                    const me = await UserService.getMe()
                    console.log("User service response:", me);
                    setUser(me);
                    AccessToken.user = me;
                } catch (err) {
                    console.error("User service error", err.name, err.message);
                    console.log("initial search url", initialSearchURL);
                    if (err.message === "None found") {
                        const urlParams = new URLSearchParams(initialSearchURL);
                        const inviteCode = urlParams.get('invite');
                        const user = (inviteCode
                            ? await UserService.invite(inviteCode)
                            : await UserService.create('default'));
                        setUser(user); 
                        history.push('/created');
                    } else {
                        history.push('/autherr');
                    }
                }
            }

            // Get Auth0 User token
            const getToken = async () => {
                try {
                    const _token = await getAccessTokenSilently();
                    console.log('Got token: ', _token);

                    if (!mounted) {
                        return;
                    }

                    AccessToken.token = _token;
                    ProVizConfig.setEnv(process.env.REACT_APP_ENV || 'local', _token);
                    setToken(_token);
                    console.log("Access token user", AccessToken.user);
                    if (AccessToken.user) {
                        return;
                    }

                    console.log('Getting user');

                    await getUser();

                    console.log('Getting personal access token');

                    const personalAccessKey = await UserKeyService.createKey();
                    console.log(personalAccessKey, process.env.REACT_APP_ENV);

                    Vuplex('user-token', {
                        token: personalAccessKey.key,
                        env: process.env.REACT_APP_ENV || 'local',
                        expires: personalAccessKey.expires
                    });
                    // This second personal access token is included to maintain compatability
                    // until  the unity codebase is updated and may be removed shortly
                    const oldPersonalAccessKey = await AccessKeyService.createPersonal();
                    Vuplex('access-token', {
                        token: oldPersonalAccessKey.key,
                        env: process.env.REACT_APP_ENV || 'local',
                        expires: oldPersonalAccessKey.expires
                    });
                } catch (e) {
                    console.log(e);
                    history.push('/autherror');
                }
            }

            getToken();

            return () => {
                mounted = false;
            }
        }, [getAccessTokenSilently, isLoading, isAuthenticated, history])

        useEffect(() => {
            if (isLoading || isAuthenticated || !token) {
                return;
            }
            const opts = {
                ...loginOptions,
                appState: {
                    ...loginOptions.appState,
                    returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
                },
            };
            (async (): Promise<void> => {
                await loginWithRedirect(opts);
            })();
        }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo, token]);

        if (!AccessToken.token && (!token || !user)) {
            return <div className="loading-indicator"></div>
        }

        const newProps = { ...props, token }
        return isAuthenticated && user
            ? <UserContext.Provider value={user}>
                <Component {...newProps} />
            </UserContext.Provider>
            : onRedirecting();
    };
}

export default withToken;