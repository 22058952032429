import { BaseDataService } from "../BaseDataService";
import { ProVizConfig } from "../Config";
import { APIFile, Paged } from "../models";
import { FileType } from "../types/FileType";
import { IServiceOptions } from "..";

class APIFileService extends BaseDataService<APIFile> {
  constructor() {
    super("file");
  }

  public async getAllPaged(
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIFile>> {
    return await this.request(
      `?page=${page}&size=${size}&filter=${filter || ""}`,
      options
    );
  }

  public async getAllByFileType(
    fileType: FileType,
    options?: IServiceOptions
  ): Promise<APIFile[]> {
    return await this.request(`type/${fileType}`, options);
  }

  public async getAllByFileTypePaged(
    fileType: FileType,
    page: number,
    size: number,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIFile>> {
    return await this.request(
      `type/${fileType}?page=${page}&size=${size}&filter=${filter || ""}`,
      options
    );
  }

  public async getAllByTag(
    tagId: string,
    fileType?: FileType,
    page: number = 0,
    size: number = 20,
    filter?: string,
    options?: IServiceOptions
  ): Promise<Paged<APIFile>> {
    return await this.request(
      `tag/${tagId}${
        fileType ? `/type/${fileType}` : ""
      }?page=${page}&size=${size}&filter=${filter || ""}`,
      options
    );
  }

  public async uploadFile(
    file: File | Blob,
    fileType: FileType,
    fileName?: string,
    options?: IServiceOptions
  ): Promise<APIFile> {
    const body = new FormData();
    body.append("file", file, fileName);

    return await this.submit(fileType, "POST", body, options);
  }

  public async uploadFileSecure(
    file: File,
    fileType: FileType,
    options?: IServiceOptions
  ): Promise<APIFile> {
    const body = new FormData();
    body.append("file", file);

    return await this.submit(`secure/${fileType}`, "POST", body, options);
  }

  public downloadSecure(file: APIFile, options?: IServiceOptions) {
    return this.download(file.displayName ?? "", {
      path: `download/${file.id}`,
      method: "GET",
      ...options,
    });
  }

  public async exportToGlobal(fileId: string, tags: string[]) {
    return await this.post("exportToGlobal", { fileId, tags });
  }

  public async update(file: APIFile) {
    const body = new FormData();
    body.append("displayName", file.displayName ?? "");
    return await this.submit(`${file.id}/update`, "PUT", body);
  }

  public getLocation(file: APIFile) {
    return ProVizConfig.selfContained
      ? `${ProVizConfig.selfContainedRoot}v1/file/${file.fileName}`
      : file.location;
  }

  public getThumbnail(file: APIFile): string {
    return this.getFullPath(`${file.id}/thumbnail`);
  }

  public setThumbnail(fileId: string, thumbnail: File) {
    const body = new FormData();
    body.append("thumbnailData", thumbnail);

    return this.submit(`${fileId}/thumbnail`, 'POST', body);
  }
}

const FileService = new APIFileService();

export default FileService;
