// import { AccessKeyService } from '@proviz/api-services';
import React, { ReactElement, useState } from 'react'
import Vuplex from '../utils/vuplex';
import LogoutLink from '../auth/LogoutLink';
import { useUserContext } from '../auth/UserContext';
import Scenes from '../dashboard/Scenes';
import VuforiaAreaTargetCreator from '../dashboard/VuforiaAreaTargetCreator';
import Banner from './Banner';
import { Modal } from './Modal';
import { UserService } from '@proviz/api-services';
import { useHistory } from 'react-router-dom';

export default function DashboardContent(): ReactElement {
    const user = useUserContext();
    const history = useHistory();

    const [ showDeleteAccount, setShowDeleteAccount ] = useState(false);
    const [ deletingAccount, setDeletingAccount ] = useState(false);

    function deleteAccount() {
        setDeletingAccount(true);
        UserService.delete(user.id).then(() => {
            setDeletingAccount(false);
            history.push('/');
        }).catch(() => {
            setDeletingAccount(false);
        });
    }

    if (!user) {
        return <div />;
    }
    
    return <div>
        <div className="site-content dash-content">
            <Banner />
        </div>
        <div className='logout flex'>
            <div className='flex-1 text-left welcome welcome-logout'>Welcome, <br className="responsive" /> {user.firstName} {user.lastName}</div>
            <div className='flex-1 text-right'><LogoutLink /></div>
        </div>
        <hr />
        <Scenes />
        <hr />
        <h4>Utilities</h4>
        <div>
            <button onClick={() => Vuplex('clear-cache')}>Clear Cache</button>
        </div>
        <div>
            <VuforiaAreaTargetCreator />
        </div>
        <div>
            <button className='danger' onClick={() => setShowDeleteAccount(true)}>Delete Account</button>
        </div>

        {showDeleteAccount && <Modal>
            <div>Are you sure you want to delete your account?</div>
            <div>
                {!deletingAccount && <button className='danger' onClick={() => deleteAccount()}>Yes, Delete Account</button>}
                {deletingAccount && <div>Deleting account...</div>}
            </div>
            <hr />
            <button onClick={() => setShowDeleteAccount(false)}>Cancel</button>
        </Modal>}
        
    </div>;
}